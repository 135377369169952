















































































































































































































































































































































































































































































































































































































































import { AppProvider } from '@/app-providers'
import { alertController } from '@/components/alert/alert-controller'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { Component, Inject, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { MarketItemDetailViewModel } from '../viewmodels/market-item-detail-viewmodel'

@Observer
@Component({
  components: {
    SaleDialog: () => import('../components/market-sale-dialog.vue'),
    ConfirmSaleDialog: () => import('../components/confirm-sale-dialog.vue'),
    BuyDialog: () => import('../components/detail-buy-dialog.vue'),
    SuccessDialog: () => import('../components/success-dialog.vue'),
    TransferDialog: () => import('../components/transfer-dialog.vue'),
    PoolCover: () => import('@/components/images/pool-cover.vue'),
    ClaimButton: () => import('../components/claim-button.vue'),
    MakeOfferDialog: () => import('../components/make-offer-dialog.vue'),
    ConfirmMakeOfferDialog: () => import('../components/confirm-make-offer-dialog.vue'),
    ApproveOfferDialog: () => import('../components/approve-offer-dialog.vue'),
  },
})
export default class extends Vue {
  // @Prop({ required: true, default: null }) vm!: MarketItemViewModel
  @Inject() providers!: AppProvider
  @Provide() vm = new MarketItemDetailViewModel()
  @Ref('transferDialog') transferDialog: any
  @Ref('saleDialog') saleDialog: any
  @Ref('confirmSaleDialog') confirmSaleDialog: any
  @Ref('buyDialog') buyDialog: any
  @Ref('makeOfferDialog') makeOfferDialog: any
  @Ref('confirmMakeOfferDialog') confirmMakeOfferDialog: any
  @Ref('successDialog') successDialog: any
  @Ref('approveOfferDialog') approveOfferDialog: any

  nftId = ''

  scheduleHeaders = [
    {
      text: 'Date',
      align: 'start',
      sortable: false,
      value: 'date',
    },
    {
      text: 'Vesting (%)',
      align: 'center',
      sortable: false,
      value: 'percentage',
    },
    {
      text: 'Amount',
      align: 'start',
      sortable: false,
      value: 'amount',
    },
    {
      text: 'Status',
      align: 'end',
      sortable: false,
      value: 'status',
    },
  ]

  @Watch('$route.params.nftId', { immediate: true }) async onIdChanged(val: string) {
    if (val) {
      this.nftId = val
      await this.loadData(val)
    }
  }

  async loadData(nftId) {
    await this.vm.loadData(nftId, this.$route.query.chainId)
  }

  async doneTransfer() {
    this.loadData(this.nftId)
  }

  openLink(url) {
    window.open(url)
  }

  async transferNftCard() {
    const ok = await this.transferDialog.open(this.vm.keyInfo, this.vm.poolStore)
    if (ok === true) {
      this.$router.replace('dashboard')
    }
  }

  async createOrder() {
    if (await this.saleDialog.open(this.vm.keyInfo, this.vm.poolStore)) {
      if (await this.confirmSaleDialog.open()) this.loadData(this.nftId)
    }
  }

  async openBuyDialog() {
    if (await this.buyDialog.open()) {
      await this.successDialog.open(this.vm.keyInfo, this.vm.poolStore)
    }
  }

  async openMakeOfferDialog() {
    if (await this.makeOfferDialog.open()) {
      await this.confirmMakeOfferDialog.open()
    }
  }
  async openApproveOfferDialog() {
    await this.approveOfferDialog.open()
  }

  async cancelSelling() {
    if (
      await alertController.confirm(
        'Cancel selling',
        `Do you want to cancel selling this NFT investment on Forage. NFT will be delisted and you can sell again after.`,
        'Confirm',
        'Cancel'
      )
    ) {
      await this.vm.cancelOrder()
    } else {
      return
    }
  }
  async cancelOffer() {
    if (
      await alertController.confirm(
        'Cancel Offer',
        `Do you want to cancel the offer for this NFT investment. 
And you can still make the other offer for this again.`,
        'Confirm',
        'Cancel'
      )
    ) {
      await this.vm.cancelOffer()
    } else {
      return
    }
  }

  isPendingClaim(date) {
    return moment(date).isAfter(moment())
  }

  destroyed() {
    this.vm.destroy()
  }
}
