import { appProvider } from '@/app-providers'
import { blockchainHandler } from '@/blockchainHandlers'
import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { WaggleForageInterface } from '@/blockchainHandlers/waggle-forage-interface'
import { WaggleForageContract } from '@/blockchainHandlers/waggle-forage-solidity'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { FIXEDNUMBER_100, Zero } from '@/constants'
import { strEquals } from '@/helpers'
import { bnHelper } from '@/helpers/bignumber-helper'
import { numberHelper } from '@/helpers/number.hepler'
// import { PoolStore } from '../stores/pool-store'
import { promiseHelper } from '@/helpers/promise-helper'
import { formatDuration } from '@/modules/ido/business/swap-contract.business'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { poolsStore } from '@/modules/ido/stores/pools-store'
import { PurchasedItemViewModel } from '@/modules/ido/viewmodels/ido-pool-detail-viewmodel'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { round } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { isCancelSolTransaction } from './market-viewmodel'

export type ForageDialogResult = 'success' | 'error' | 'cancel-error'

export class MarketItemDetailViewModel {
  waggleForageContract: WaggleForageInterface | null = null

  @observable keyInfo?: MarketKeyInfo = undefined
  @observable poolStore?: PoolStore = undefined
  @observable ownerAddress = ''
  // @observable contract?: MarketNftContract
  @observable selling = false
  @observable order: any

  @observable buying = false
  @observable cancellingOrder = false
  @observable cancellingOffer = false
  @observable approving = false
  @observable approved = false

  @observable tradeTokenBalance = FixedNumber.from('0')
  @observable chainId = 97
  @observable sellerTax = Zero
  @observable buyerTax = Zero

  _disposers: IReactionDisposer[] = []
  @observable trade2IdoToken = false

  @observable offerRatioInput = ''
  @observable offerPriceInput = ''
  @observable makeOfferLoading = false
  @observable makeOfferError = ''
  @observable claimingOrder = false

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          this.fetchMyMarketInfos()
          this.fetchTradeTokenBalance()
        },
        { fireImmediately: true }
      ),
    ]
  }

  @asyncAction *fetchTradeTokenBalance() {
    const contract = this.waggleForageContract
    const account = walletStore.account
    if (!contract || !account || walletStore.chainId !== this.chainId) return
    try {
      this.tradeTokenBalance = yield contract.getUserTradeTokenAmount(walletStore.account)
    } catch (e: any) {
      console.log('tradeTokenBalance error', e.msg || e.message)
      this.tradeTokenBalance = Zero
    }
  }

  @asyncAction *fetchMyMarketInfos() {
    const contract = this.waggleForageContract
    const account = walletStore.account
    if (!contract || !account || walletStore.chainId !== this.chainId) return
    if (walletStore.chainType === 'sol') {
      this.approved = true
    } else {
      const solContract = contract as WaggleForageContract
      const tradeErc20Contract = solContract.tradeErc20Contract
      if (!tradeErc20Contract) return
      this.approved = yield solContract.isApproved(account)
      yield promiseHelper.delay(1)
    }
  }

  @asyncAction *approveUsd() {
    const contract = this.waggleForageContract as WaggleForageContract
    if (!contract) throw 'Contract is undefined'
    this.approving = true
    try {
      yield contract.approve(walletStore.account)
      this.approved = true
      snackController.success('Approved')
    } catch (error) {
      snackController.blockchainError(error)
    } finally {
      this.approving = false
    }
  }

  @action checkOfferInput() {
    if (!this.isValidOfferPriceInput) {
      this.makeOfferError = 'Your offer must be higher than the current offer'
      return false
    }
    if (!bnHelper.gte(this.tradeTokenBalance, this.buyerTotalPay)) {
      this.makeOfferError = 'Balance is not sufficient'
      return false
    }
    return true
  }

  @asyncAction *claimOrder() {
    this.claimingOrder = true
    if (!this.order || !this.order.id || !this.selling) {
      snackController.error(`Error occurred when approving offer!`)
      return 'error'
    }
    try {
      if (!this.waggleForageContract) throw 'waggleForageContract is undefined'
      yield this.waggleForageContract.claimOrder(walletStore.account, this.order)
      yield this.fetchTradeTokenBalance()
      snackController.success(`Approve offer for NFT #${this.keyId} successfully!`)
      try {
        if (!this.keyInfo) throw 'keyInfo is undefined'
        yield this.loadData(this.keyInfo.id.toString(), walletStore.chainId)
      } catch (e) {
        //
      }
      return 'success'
    } catch (error) {
      snackController.blockchainError(error)
      return isCancelSolTransaction(this.poolStore, error)
    } finally {
      this.claimingOrder = false
    }
  }

  @asyncAction *buy(isMakeOffer = false) {
    this.buying = true
    if (!this.order || !this.order.id || !this.selling) {
      snackController.error(`Error occurred when ${isMakeOffer ? 'making offer' : 'buying'} NFT!`)
      return 'error'
    }
    try {
      if (!this.waggleForageContract) throw 'waggleForageContract is undefined'
      if (isMakeOffer && !this.isValidOfferPriceInput) throw 'Offer price input is invalid'
      const price = isMakeOffer ? FixedNumber.from(this.offerPriceInput) : this.order.price
      yield this.waggleForageContract.buy(walletStore.account, this.order, price)
      const isBuyed = bnHelper.equal(price, this.order.price)
      snackController.success(`${!isBuyed ? 'Make offer for' : 'Buy'} NFT #${this.keyId} successfully!`)
      yield this.fetchTradeTokenBalance()
      try {
        if (!this.keyInfo) throw 'keyInfo is undefined'
        yield this.loadData(this.keyInfo.id.toString(), walletStore.chainId)
      } catch (e) {
        //
      }
      return 'success'
    } catch (error: any) {
      snackController.blockchainError(error)
      return isCancelSolTransaction(this.poolStore, error)
    } finally {
      this.buying = false
    }
  }

  @asyncAction *cancelOrder() {
    this.cancellingOrder = true
    if (!this.order || !this.order.id || !this.selling) {
      snackController.error('Error occurred when cancelling NFT order!')
      return false
    }
    try {
      if (!this.waggleForageContract) throw 'waggleForageContract is undefined'
      yield this.waggleForageContract.cancelOrder(walletStore.account, this.order)
      snackController.success(`Cancel NFT order successfully!`)
      try {
        if (this.keyInfo) yield this.loadData(this.keyInfo.id.toString(), walletStore.chainId)
      } catch (e) {
        console.log(e)
        //
      }
      return true
    } catch (error) {
      snackController.blockchainError(error)
      return false
    } finally {
      this.cancellingOrder = false
    }
  }
  @asyncAction *cancelOffer() {
    this.cancellingOffer = true
    if (!this.order || !this.order.id || !this.selling) {
      snackController.error('Error occurred when cancelling offer!')
      return false
    }
    try {
      if (!this.waggleForageContract) throw 'waggleForageContract is undefined'
      yield this.waggleForageContract.cancelOffer(walletStore.account, this.order)
      snackController.success(`Cancel offer successfully!`)
      try {
        if (this.keyInfo) yield this.loadData(this.keyInfo.id.toString(), walletStore.chainId)
      } catch (e) {
        console.log(e)
        //
      }
      return true
    } catch (error) {
      snackController.blockchainError(error)
      return false
    } finally {
      this.cancellingOffer = false
    }
  }

  @asyncAction *loadData(keyId: string, chainId: any) {
    try {
      chainId = +chainId || walletStore.chainId || 56 // defaut BSC
      this.chainId = chainId
      const contract = blockchainHandler.getWaggleForageContract(chainId)
      if (!contract) throw 'Contract is undefined'
      this.waggleForageContract = contract
      yield contract.init()
      this.sellerTax = (contract as any).sellerTax
      this.buyerTax = (contract as any).buyerTax
      const { selling, owner, keyInfo, market, order } = yield contract.getNftInfo(keyId)
      this.selling = selling
      this.order = order
      this.ownerAddress = owner
      this.keyInfo = keyInfo
      const allPools: PoolStore[] = yield poolsStore.fetchPoolsIfNeed()
      this.poolStore = allPools.find((p) => p.pool.address?.toLowerCase() === market.toLowerCase())
      this.fetchMyMarketInfos()
      this.fetchTradeTokenBalance()
    } catch (error) {
      console.error(error)
      snackController.error(`NFT Card "${keyId}" might not be exist`)
      appProvider.router.replace('/dashboard')
    }
  }

  @action.bound togglePriceShow() {
    this.trade2IdoToken = !this.trade2IdoToken
  }

  @computed get purchases() {
    this.ownerWalletConnected // keep here for trigger render
    return (
      this.keyInfo?.purchases.map(
        (p) =>
          new PurchasedItemViewModel(
            p,
            this.poolStore as any,
            () => {
              this.loadData((this.keyInfo as any).id.toString(), this.chainId)
            },
            this.keyInfo?.id || 0
          )
      ) || []
    )
  }

  @computed get isCorrectNetwork() {
    return walletStore.chainId === this.requiredChainId
  }
  @computed get nftId() {
    if (!this.keyInfo || !this.keyInfo.id) return 'Unknown'
    return this.keyInfo.id
  }
  @computed get hasSocialInformations() {
    const { medium, web, telegram, twitter } = this.pool?.data || {}
    return medium || web || telegram || twitter
  }
  @computed get medium() {
    return this.pool?.data?.medium
  }
  @computed get web() {
    return this.pool?.data?.web
  }
  @computed get twitter() {
    return this.pool?.data?.twitter
  }
  @computed get telegram() {
    return this.pool?.data?.telegram
  }
  @computed get requiredChain() {
    return this.poolStore?.chain
  }
  @computed get requiredChainId() {
    return this.poolStore?.chainId
  }
  @computed get ownerWalletConnected() {
    return this.ownerAddress?.toLowerCase() === walletStore.account.toLowerCase()
  }

  @computed get pool() {
    return this.poolStore?.pool
  }
  @computed get publishedAt() {
    return this.poolStore?.pool.createdAt || ''
  }
  @computed get poolName() {
    return this.pool?.name || ''
  }
  @computed get coverUrl() {
    return this.poolStore?.coverUrl || ''
  }
  @computed get logoImage() {
    return this.poolStore?.logoImage || ''
  }
  @computed get tokenName() {
    return this.pool?.tokenName || ''
  }
  @computed get tokenAddress() {
    return this.pool?.tokenAddress
  }
  @computed get tradeValue() {
    return this.poolStore?.tradeValue || ''
  }
  @computed get tradeToken() {
    return this.poolStore?.tradeToken || ''
  }
  @computed get totalSupply() {
    return this.pool?.totalSupply || 0
  }
  @computed get ratio() {
    return this.pool?.ratio
  }
  @computed get projectname() {
    return this.pool?.name
  }
  @computed get marketPrice() {
    return this.poolStore?.marketPrice
  }
  @computed get nftSize() {
    return this.keyInfo?.boughtAmounts || FixedNumber.from('0')
  }
  @computed get remainingToken() {
    if (!this.keyInfo) return FixedNumber.from('0')
    return this.keyInfo?.remain
  }
  @computed get vestingPercentage() {
    if (!this.keyInfo?.redeemedAmounts || !this.keyInfo?.boughtAmounts) return FixedNumber.from('0')
    return this.keyInfo?.redeemedAmounts.divUnsafe(this.keyInfo?.boughtAmounts).mulUnsafe(FixedNumber.from(100))
  }
  @computed get purchasedValue() {
    if (!this?.tradeValue) return FixedNumber.from('0')
    return this.keyInfo?.boughtAmounts.mulUnsafe(FixedNumber.from(this.tradeValue + ''))
  }
  @computed get keyId() {
    return this.keyInfo?.id
  }
  @computed get vestingDuration() {
    if (!this.poolStore || !this.poolStore.vestingDuration) return 0
    return this.poolStore?.vestingDuration
  }
  @computed get sellingPrice() {
    if (!this.selling || !this.order || !this.order.price) return ''
    return this.order.price
  }

  // add new

  @action resetOfferInput() {
    this.offerRatioInput = ''
    this.offerPriceInput = ''
    this.makeOfferError = ''
  }

  @action.bound offerRatioInputOnChange(input) {
    this.makeOfferError = ''
    if (input && numberHelper.isFloatNumber(input)) {
      this.offerRatioInput = input
      this.offerPriceInput = this.remainingToken.mulUnsafe(FixedNumber.from(`${input}`)).toString()
      this.offerPriceInput = (+this.offerPriceInput).toString()
    } else {
      this.offerPriceInput = ''
    }
  }
  @action.bound offerPriceInputOnChange(input) {
    this.makeOfferError = ''
    if (input && numberHelper.isFloatNumber(input) && !this.remainingToken.isZero()) {
      this.offerPriceInput = input
      this.offerRatioInput = FixedNumber.from(`${input}`).divUnsafe(this.remainingToken).toString()
      this.offerRatioInput = (+this.offerRatioInput).toString()
    } else {
      this.offerRatioInput = ''
    }
  }

  @computed get sellerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.sellerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }

  @computed get buyerTaxPrice() {
    if (!this.buyerBidPrice || this.buyerBidPrice.isZero()) return Zero
    return this.buyerTax.mulUnsafe(this.buyerBidPrice).divUnsafe(FIXEDNUMBER_100)
  }
  @computed get buyerBidPrice() {
    return this.order?.buyerBidPrice || Zero
  }
  @computed get sellerRecievedUsd() {
    return this.buyerBidPrice.subUnsafe(this.sellerTaxPrice)
  }
  @computed get offerPricePerToken() {
    if (!this.remainingToken || this.remainingToken.isZero()) return Zero
    return this.buyerBidPrice.divUnsafe(this.remainingToken)
  }

  @computed get isNotOffer() {
    return this.buyerBidPrice.isZero()
  }
  @computed get buyer() {
    return this.order?.buyer || ''
  }
  @computed get isCurrentOfferBuyer() {
    return this.buyer && strEquals(this.buyer, walletStore.account)
  }
  @computed get seller() {
    return this.order?.seller || ''
  }

  @computed get isValidOfferPriceInput() {
    if (!numberHelper.isFloatNumber(this.offerPriceInput) || +this.offerPriceInput <= 0) return false
    return bnHelper.gt(FixedNumber.from(this.offerPriceInput), this.buyerBidPrice)
  }

  @computed get offerBuyerTaxPrice() {
    return this.isValidOfferPriceInput
      ? FixedNumber.from(this.offerPriceInput).mulUnsafe(this.buyerTax).divUnsafe(FIXEDNUMBER_100)
      : Zero
  }

  @computed get buyerTotalPay() {
    return this.isValidOfferPriceInput
      ? this.offerBuyerTaxPrice.addUnsafe(FixedNumber.from(this.offerPriceInput))
      : Zero
  }
  @computed get formattedRemainingToken() {
    if (!this.keyInfo) return FixedNumber.from('0')
    return numberHelper.formatNumber(this.keyInfo?.remain, 5, 0)
  }
  @computed get createdTime() {
    return formatDuration(moment.duration(moment().diff(this.order.createdAt))) + ' ago'
  }

  @computed get serviceFee() {
    if (!this.order || !this.order.price) return ''
    return numberHelper.formatNumber(this.order.price.divUnsafe(FixedNumber.from('20')), 6, 2)
  }
  @computed get formattedPrice() {
    return this.order?.price || '0'
  }

  @computed get formattedTotalPrice() {
    if (!this.order || !this.order.price) return ''
    return numberHelper.formatNumber(this.order.price, 6, 2)
  }

  @computed get price() {
    if (!this.order || !this.order.price) return FixedNumber.from('0')
    return this.order.price
  }
  @computed get pricePerToken() {
    if (!this.price || this.price.isZero()) return FixedNumber.from('0')
    return this.price.divUnsafe(this.remainingToken)
  }
  @computed get tradeTokenSufficient() {
    return bnHelper.gt(this.price, this.tradeTokenBalance)
  }
  @computed get priceText() {
    if (this.trade2IdoToken) {
      const val = round(FixedNumber.from('1').divUnsafe(this.pricePerToken).toUnsafeFloat(), 5)
      return `1 ${this.tradeToken} = ${val} ${this.tokenName || ''}`
    } else {
      const val = round(this.pricePerToken.toUnsafeFloat(), 5)
      return `1 ${this.tokenName} = ${val} ${this.tradeToken || ''}`
    }
  }
  @computed get isSolana() {
    const chainId = +this.chainId
    return chainId === 101 || chainId === 103
  }

  @computed get disableTransfer() {
    return this.pool?.data?.disableTransfer
  }

  async destroy() {
    await promiseHelper.delay(1)
    this._disposers.forEach((d) => d())
  }
}
